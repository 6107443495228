<script>
import { _request } from '@/helpers/_request.js'
import Parser from '@/helpers/_responseParser.js'

export default {
    name:'ReportService',
    methods : {
        GetFullTransaction(data) {
            return _request('csGetTransactionReport',data).then(Parser.toXml.bind(null,'csGetTransactionReportResponse'))
        },
        GetCustomerReport(data) {
            return _request('csGetCustomerExtract', data).then(Parser.toXml.bind(null, 'csGetCustomerExtractResponse'))
        },
        GetReferralSummary(data) {
            return _request('csGetReferralReport', data).then(Parser.toXml.bind(null, 'csGetReferralReportResponse'))
        },
        GetPromoUsageSummary(data) {
            return _request('csGetPromoCodeUsage', data).then(Parser.toXml.bind(null, 'csGetPromoCodeUsageResponse'))
        },
        GetTransStatsByDestCountry (data) {
            return _request('csGetTxnStatsByDestCountry', data).then(Parser.toXml.bind(null, 'csGetTxnStatsByDestCountryResponse'))
        },
        GetTransStatsByDestCountryByDay (data) {
            return _request('csGetTxnStatsByDestCountryByDay', data).then(Parser.toXml.bind(null, 'csGetTxnStatsByDestCountryByDayResponse'))
        },
        GetTransStatsByDevice (data) {
            return _request('csGetTxnStatsByDevice', data).then(Parser.toXml.bind(null, 'csGetTxnStatsByDeviceResponse'))
        },
        GetTransStatsByRemittanceGateway (data) {
            return _request('csGetTxnStatsByRemittanceGateway', data).then(Parser.toXml.bind(null, 'csGetTxnStatsByRemittanceGatewayResponse'))
        },
        GetUsersActiveByMonth (data) {
            return _request('csGetUsersActiveListByMonth', data).then(Parser.toXml.bind(null, 'csGetUsersActiveListByMonthResponse'))
        },
        GetUsersSignedUpByMonth (data) {
            return _request('csGetUserSignUpStatsByMonth', data).then(Parser.toXml.bind(null, 'csGetUserSignUpStatsByMonthResponse'))
        },
        GetCustomerRecentLogins (data) {
            return _request('csGetCustomerRecentLogin', data).then(Parser.toXml.bind(null, 'csGetCustomerRecentLoginResponse'))
        }
    }
}
</script>